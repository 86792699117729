import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
// ---------------------------------------------
import Markdown from "../components/markdown"
import PhotoViewer from "../components/photoviewer"
// ---------------------------------------------

function currencyFormat(num) {
  return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}

const Swatches = ({ swatches, basePrice, hideVariants, hideZoom }) => {
  const [selection, setSelection] = useState(swatches[0])
  const [totalPrice, setTotalPrice] = useState(0)

  const handleVideoTouch = e => {
    e.target.play()
  }

  const checkPrice = (a, b) => {
    return a + b >= 10000 ? currencyFormat(a + b) : a + b
  }

  useEffect(() => {
    setTotalPrice(checkPrice(basePrice, selection.priceAdditional))
  }, [selection])

  return selection.swatch ? (
    <div className="swatch-select">
      {/* Thumbnail, Zoomer and Video Preview */}
      <div className="swatch-thumbnail padx2">
        {/* {!hideZoom ? (
          <PhotoViewer thumbnail={selection.swatch.image.thumbnail} hires={selection.swatch.image.hires} />
        ) : (
          <Img fluid={selection.swatch.image.thumbnail} />
        )} */}
        <Img fluid={selection.swatch.image.thumbnail} />

        {selection.swatch.video && (
          <div className="pabs fs preview">
            <div className="prel fs ohide">
              <div
                className="pabs"
                style={{
                  width: "105%",
                  height: "105%",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <video
                  src={selection.swatch.video.file.url}
                  className="pabs fit-cover"
                  autoPlay
                  muted
                  loop
                  playsInline
                  onTouchStart={handleVideoTouch}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Swatch info and options */}
      <div className="swatch-info guttersx2">
        <div className="swatch-name">
          <h5>{selection.swatch.name}</h5>
          {selection.swatch.shortDescription ? (
            <Markdown field={selection.swatch.shortDescription} />
          ) : (
            <p>{selection.swatch.description}</p>
          )}
        </div>

        {hideVariants || (
          <div className="swatch-options">
            <ul className="swatch-colours innerx2 no-bullets">
              {swatches.map(node => {
                // console.log(node)
                return (
                  <li
                    className={`swatch-option ${
                      node.ref === selection.ref ? "active" : ""
                    }`}
                    onClick={() => setSelection(node)}
                    key={node.ref}
                    style={{
                      backgroundColor: node.swatch.colour || "",
                    }}
                  />
                )
              })}
            </ul>
            {basePrice > 0 && (
              <div className="swatch-price">
                <div className="price innerx2">
                  <p>{`£${totalPrice}`}</p>
                  <p>
                    <em>{`(Total price inc. ${selection.swatch.material.fabricOrLeather})`}</em>
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  ) : (
    false
  )
}

export const SwatchesV2 = ({ swatches = [], hideVariants }) => {
  const sortedByColour = swatches // better to be ordered manually
  // .sort((a, b) => {
  //   return a.colour < b.colour ? 1 : -1
  // })
  const [selection, setSelection] = useState(sortedByColour?.[0])
  // const [totalPrice, setTotalPrice] = useState(0)

  const handleVideoTouch = e => {
    e.target.play()
  }

  // const checkPrice = (a, b) => {
  //   return a + b >= 10000 ? currencyFormat(a + b) : a + b
  // }

  // useEffect(() => {
  //   setTotalPrice(checkPrice(basePrice, selection.priceAdditional))
  // }, [selection])

  return selection ? (
    <div className="swatch-select">
      {/* Thumbnail, Zoomer and Video Preview */}
      <div className="swatch-thumbnail padx2">
        {/* {!hideZoom ? (
          <PhotoViewer thumbnail={selection.swatch.image.thumbnail} hires={selection.swatch.image.hires} />
        ) : (
          <Img fluid={selection.swatch.image.thumbnail} />
        )} */}
        <Img fluid={selection.image.thumbnail} />

        {selection.video && (
          <div className="pabs fs preview">
            <div className="prel fs ohide">
              <div
                className="pabs"
                style={{
                  width: "105%",
                  height: "105%",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <video
                  src={selection.video.file.url}
                  className="pabs fit-cover"
                  autoPlay
                  muted
                  loop
                  playsInline
                  onTouchStart={handleVideoTouch}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Swatch info and options */}
      <div className="swatch-info guttersx2">
        <div className="swatch-name">
          <h5>{selection.name}</h5>
          {/* {selection.shortDescription ? (
            <Markdown field={selection.shortDescription} />
          ) : (
            <p>{selection.description}</p>
          )} */}
        </div>

        {hideVariants || (
          <div className="swatch-options">
            <ul className="swatch-colours innerx2 no-bullets">
              {sortedByColour?.map(node => {
                // console.log(node)
                return (
                  <li
                    className={`swatch-option ${
                      node.name === selection.name ? "active" : ""
                    }`}
                    onClick={() => setSelection(node)}
                    key={node.name}
                    style={{
                      backgroundColor: node.colour || "",
                    }}
                  />
                )
              })}
            </ul>
            {/* {basePrice > 0 && (
              <div className="swatch-price">
                <div className="price innerx2">
                  <p>{`£${totalPrice}`}</p>
                  <p>
                    <em>{`(Total price inc. ${selection.swatch.material.fabricOrLeather})`}</em>
                  </p>
                </div>
              </div>
            )} */}
          </div>
        )}
      </div>
    </div>
  ) : (
    false
  )
}

export default Swatches

export const ProductSwatchFragment = graphql`
  fragment Swatch on ContentfulSwatch {
    name
    material {
      type
      fabricOrLeather
    }
    description # soon to be deprecated...
    shortDescription {
      childMarkdownRemark {
        html
      }
    }
    colour
    image {
      thumbnail: fluid(maxWidth: 800, quality: 50) {
        ...GatsbyContentfulFluid_noBase64
      }
      hires: fluid(maxWidth: 2000, quality: 95) {
        ...GatsbyContentfulFluid_noBase64
      }
    }
    video {
      file {
        url
      }
    }
    gallery {
      id
      fluid(maxWidth: 800) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      file {
        contentType
        url
      }
    }
  }

  fragment ProductSwatch on ContentfulProductSwatch {
    ref
    # price
    priceAdditional
    swatch {
      ...Swatch
    }
  }
`
