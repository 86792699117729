// Dependencies
import React from "react"
import Markdown from "./markdown"

const Materials = ({
  frame,
  fabric,
  cushionOptions,
  hideMessage,
  children,
}) => (
  <div className="product-materials">
    {children || <h5>Materials</h5>}
    {frame && (
      <>
        <p>Construction</p>
        <Markdown className="md" field={frame} />
      </>
    )}

    {cushionOptions && (
      <>
        <p>Upholstery</p>
        <Markdown className="md mbx2" field={cushionOptions} />
      </>
    )}

    {fabric && (
      <>
        <p>Fabric</p>
        <Markdown className="md mbx2" field={fabric} />
      </>
    )}

    {!hideMessage && (
      <p>
        <em>Custom sizing and finishing upon request.</em>
      </p>
    )}
  </div>
)

export default Materials
