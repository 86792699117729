import React from "react"

// ---------------------
// Animation helpers
//

// easing functions http://goo.gl/5HLl8
Math.easeInOutQuad = function (t, b, c, d) {
  t /= d/2;
  if (t < 1) {
    return c/2*t*t + b
  }
  t--;
  return -c/2 * (t*(t-2) - 1) + b;
};

Math.easeInCubic = function(t, b, c, d) {
  var tc = (t/=d)*t*t;
  return b+c*(tc);
};

Math.inOutQuintic = function(t, b, c, d) {
  var ts = (t/=d)*t,
  tc = ts*t;
  return b+c*(6*tc*ts + -15*ts*ts + 10*tc);
};


// ---------------------
// Component
//

const ScrollToLink = ({ className, children, target, startCallback }) => {

  function position() {
    return document.documentElement.scrollTop || document.body.parentNode.scrollTop || document.body.scrollTop;
  }

  const handleClick = () => {

    const endY = target.current.getBoundingClientRect().top + position();

    if (endY > 0) {
      startCallback()
      scrollTo(endY - 64, 2800)
    } else {
      console.log(target.current)
    }
  }

  function scrollTo(to, duration, callback) {

    // requestAnimationFrame for Smart Animating http://goo.gl/sx5sts
    var requestAnimFrame = (function(){
      return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || function( callback ){ window.setTimeout(callback, 1000 / 60); };
    })();

    // because it's so fucking difficult to detect the scrolling element, just move them all
    function move(amount) {
      document.documentElement.scrollTop = amount;
      document.body.parentNode.scrollTop = amount;
      document.body.scrollTop = amount;
    }

    // function position() {
    //   return document.documentElement.scrollTop || document.body.parentNode.scrollTop || document.body.scrollTop;
    // }

    var start = position(),
        change = to - start,
        currentTime = 0,
        increment = 20;
        duration = (typeof(duration) === 'undefined') ? 500 : duration;

    var animateScroll = function() {
      // increment the time
      currentTime += increment;
      // find the value with the quadratic in-out easing function
      var val = Math.easeInOutQuad(currentTime, start, change, duration);
      // move the document.body
      move(val);
      // do the animation unless its over
      if (currentTime < duration) {
        requestAnimFrame(animateScroll);
      } else {
        if (callback && typeof(callback) === 'function') {
          // the animation is done so lets callback
          callback();
        }
      }
    };

    animateScroll();
  }

  // const scrollTo = (endY, duration) => {
  //   // endY = endY || ($.os.android ? 1 : 0);
  //   // duration = duration || 200;
  //
  //   var startY = document.body.scrollTop,
  //       startT  = +(new Date()),
  //       finishT = startT + duration;
  //
  //   var interpolate = function (source, target, shift) {
  //     return (source + (target - source) * shift);
  //   };
  //
  //   var easing = function (pos) {
  //     return (-Math.cos(pos * Math.PI) / 2) + .5;
  //   };
  //
  //   var animate = function() {
  //     var now = +(new Date()),
  //         shift = (now > finishT) ? 1 : (now - startT) / duration;
  //
  //     window.scrollTo(0, interpolate(startY, endY, easing(shift)));
  //
  //     (now > finishT) || setTimeout(animate, 15);
  //   };
  //
  //   animate();
  // }

  return (
    <button className={className} onClick={handleClick}>{children}</button>
  )
}

export default ScrollToLink
